/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.ion-margin-bottom {
  --ion-margin: 8px;
  // --margin-bottom: var(--ion-margin, 16px);
  // margin-bottom: var(--ion-margin, 16px);
}

// FONT SIZE
html{
  font-size: var(--font-size-base); // 16px
}
h1 {
  margin-top: 1.250rem;//20px;
  font-size: 1.625rem;// 26px;
}
h2 {
  margin-top: 1.125rem; //18px;
  font-size: 1.500rem; //24px;
}
h3 {
  font-size: 1.375rem; //22px;
}
h4 {
  font-size: 1.250rem; //20px;
}
h5 {
  font-size: 1.125rem; //18px;
}
h6 {
  font-size: 1.000rem; //16px;
}
small {
  font-size: 75%;
}
sub,
sup {
  font-size: 75%;
}

ion-item{
  --min-height: 3.000rem;
}
button.alert-tappable{
  height: 3.000rem;
}

ion-card-title,
ion-card-header{
  font-size: 1.250rem;
}
ion-card-header{
  padding: 0.250rem;
}

ion-label,
ion-input,
ion-select,
.alert-radio-label{
  font-size: 1.000rem !important;
}
ion-card-title {
  color: var(--ion-color-gray) !important;
}

ion-fab-button{
  &.fab-button-small{
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
    width: 2.5rem !important;
    height: 2.5rem !important;
  }
}

body,*{
  scrollbar-width: thin;
  scrollbar-color: var(--ion-color-primary) transparent;
}
*::-webkit-scrollbar {
  width: 5px;
}
*::-webkit-scrollbar-track {
  background-color: transparent;
}
*::-webkit-scrollbar-thumb {
    background-color: var(--ion-color-primary);
    border: 1px solid transparent;
    border-radius: 10px;
    background-clip: content-box;
}

ion-fab,ion-fab-button{
  --box-shadow: none;
}
.js-plotly-plot .plotly{
  height: 220px;
}
.icon-inner{
  color:#fff;
}